export const routes = [
    {
        path: '/',
        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/front/index.vue'),

        children: [
            // TESTS
            {
                path: '/tests',
                component: () => import(/* webpackChunkName: "Common" */ './layouts/empty.vue'),
                children: [
                    {
                        path: '/',
                        component: () => import(/* webpackChunkName: "Common" */ '@/tests/index.vue')
                    },
                ]
            },

            // Auth
            {
                path: 'auth',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'login',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/login.vue')
                    },
                    {
                        path: 'logout',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/Logout.vue')
                    },
                    {
                        path: 'join',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'agree',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/agree.vue')
                            },
                            {
                                path: 'form',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/join/form.vue')
                            }
                        ]
                    },
                    {
                        path: 'find',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'id',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/id.vue')
                            },
                            {
                                path: 'password',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/find/password.vue')
                            }
                        ]
                    },
                    {
                        path: 'email_certification',
                        component: () => import(/* webpackChunkName: "Auth" */ '@/views/auth/EmailCertification.vue')
                    },
                    {
                        path: 'sns',
                        component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                        children: [
                            {
                                path: 'google',
                                component: () => import(/* webpackChunkName: "Common" */ '@/views/auth/sns/google.vue')
                            },
                        ]
                    }
                ]
            },

            // 홈
            {
                path: 'home',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/home.vue')
            },

            // 알람
            {
                path: 'alarm',
                component: () => import(/* webpackChunkName: "Common" */ '@/layouts/empty.vue'),
                children: [
                    {
                        path: 'address',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/alarm/address.vue')
                    },
                    {
                        path: 'weather',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/alarm/weather.vue')
                    },
                    {
                        path: 'select',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/alarm/select.vue')
                    },
                    {
                        path: 'category',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/alarm/category.vue')
                    },
                    {
                        path: 'type',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/alarm/type.vue')
                    },
                    {
                        path: 'value',
                        component: () => import(/* webpackChunkName: "Common" */ '@/views/alarm/value.vue')
                    },
                ]
            },

            // 운세
            {
                path: 'lotto',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/lotto.vue')
            },

            // 운세
            {
                path: 'fortune',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/fortune.vue')
            },

            // 운세
            {
                path: 'custom',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/custom.vue')
            },

            // MY
            {
                path: 'mypage',
                component: () => import(/* webpackChunkName: "Admin" */ '@/views/mypage.vue')
            },

            // 알람 설정
            // {
            //     path: 'alarm',
            //     component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
            //     children: [
            //         {
            //             path: 'category',
            //             component: () => import(/* webpackChunkName: "Admin" */ '@/views/front/alarm/category.vue')
            //         },
            //         // {
            //         //     path: 'condition',
            //         //     component: () => import(/* webpackChunkName: "Admin" */ '@/views/front/alarm/condition.vue')
            //         // },
            //         {
            //             path: 'type',
            //             component: () => import(/* webpackChunkName: "Admin" */ '@/views/front/alarm/type.vue')
            //         },
            //         {
            //             path: 'value',
            //             component: () => import(/* webpackChunkName: "Admin" */ '@/views/front/alarm/value.vue')
            //         },
            //     ]
            // },

            // 마이페이지
            // {
            //     path: 'mypage',
            //     component: () => import(/* webpackChunkName: "Common" */ './layouts/front/index.vue'),
            //     children: [
            //         {
            //             path: 'update',
            //             component: () => import(/* webpackChunkName: "Admin" */ '@/views/front/mypage/update.vue')
            //         },
            //     ]
            // },
        ]
    },
]