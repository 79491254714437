import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        // 사용자
        alarmman_user: {
            is_logined: false,
            user_id: "",
            nickname: "",
            email: "",
            profile_image: "",
            type: "",
            status: "",
            created: ""
        },

        // 관리자
        client_admin: {
            is_logined: false,
        }
    },

    getters: {

    },

    mutations: {
        // 사용자 로그인
        login: (state, payload) => {
            state.alarmman_user.is_logined = true
            state.alarmman_user.user_id = payload.user_id
            state.alarmman_user.nickname = payload.nickname
            state.alarmman_user.email = payload.email
            state.alarmman_user.profile_image = payload.profile_image
            state.alarmman_user.type = payload.type
            state.alarmman_user.status = payload.status
            state.alarmman_user.created = payload.created
        },

        // 사용자 로그아웃
        logout: (state) => {
            state.alarmman_user.is_logined = false
            state.alarmman_user.user_id = ""
            state.alarmman_user.nickname = ""
            state.alarmman_user.email = ""
            state.alarmman_user.profile_image = ""
            state.alarmman_user.type = ""
            state.alarmman_user.status = ""
            state.alarmman_user.created = ""
        },

        // 사용자 프로필 수정하기
        update_profile_image: (state, payload) => {
            state.alarmman_user.profile_image = payload
        },

        // 관리자 로그인
        admin_login: (state) => {
            state.client_admin.is_logined = true
        },

        // 관리자 로그아웃
        admin_logout: (state) => {
            state.client_admin.is_logined = false
        },

        // 언어 변경
        language_change: (state, payload) => {
            if(state.language == "kr"){
                state.language = "en"
            }else{
                state.language = "kr"
            }
        },
    },

    actions: {

    },

    modules: {

    },

    plugins: [
        // 브라우져 꺼져도 유지
        createPersistedState({
            paths: [
                'alarmman_user',
                'client_admin',
                'language'
            ]
        })
    ]
})
