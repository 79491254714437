import Vue from 'vue'
import App from './App.vue'
import Router from 'vue-router'
import { routes } from './routes.js'
import store from './store.js'
import vuetify from './plugins/vuetify'
import lodash from 'lodash'
import '@babel/polyfill'

// Firebase v9 모듈 가져오기 (모듈화 업데이트된 Firebase SDK 버전 9 이후)
import { initializeApp } from "firebase/app"

// Firebase 메시징 모듈
import { getMessaging, onMessage, getToken } from "firebase/messaging"

// Firebase 구성 설정
const firebaseConfig = {
    apiKey: "AIzaSyBlRrGq1Wa0gYRZ2UMlv4WnUkQHbjcSC0c",
    authDomain: "alarmman-36739.firebaseapp.com",
    databaseURL: "https://alarmman-36739-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "alarmman-36739",
    storageBucket: "alarmman-36739.appspot.com",
    messagingSenderId: "712381605531",
    appId: "1:712381605531:web:fc3e694cd944a32ad8083e",
    measurementId: "G-R74J8HSEJV"
}

// Firebase > 초기화
const app = initializeApp(firebaseConfig)

// Firebase > 메시징 초기화
const messaging = getMessaging(app)

// 서비스워커 등록
navigator.serviceWorker.register('/firebase-messaging-sw.js')
.then((registration) => {
    console.log(registration)
})

// Firebase > 포그라운드에서 Firebase 푸시 알림 수신 리스너 추가
onMessage(messaging, (payload) => {
    console.log('Message received. ', payload)

    // 알림 제목과 내용 설정
    // const notificationTitle = payload.notification.title
    const notificationTitle = "main.js - onMessage"
    const notificationOptions = {
        body: payload.notification.body,
        icon: payload.notification.icon || '/icon.png' // 기본 아이콘 경로
    }

    new Notification("1"+notificationTitle, notificationOptions)

    // Notification API를 사용하여 알림 표시
    if (Notification.permission === "granted") {
        new Notification("2"+notificationTitle, notificationOptions)
    }
})

// Firebase > 사용자에게 푸시알림 권한요청
getToken(messaging, { vapidKey: 'BNnJNLsmlH7oa58jngScwIdvkIX4Rmi_kljRMmhD8NRbxzO0NYR7jjWbhewqiC6HZzHjjlIJhvWIjwfOTlYcrco' })
.then((currentToken) => {
    if (currentToken) {
        console.log('Token:', currentToken)
    } else {
        console.log('No registration token available. Request permission to generate one.')
    }
}).catch((err) => {
    console.log('An error occurred while retrieving token. ', err)
})

// Axios
import axios from 'axios'
Vue.prototype.$http = axios

// CustomMethods
import CustomMethods from './methods'
Vue.use(CustomMethods)

Vue.use(Router)
Vue.config.productionTip = false
export const eventBus = new Vue()

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

new Vue({
    router,
    store,
    vuetify,
    lodash,
    render: h => h(App)
}).$mount('#app')