<template>
    <v-app>
        <!-- 테스트 모드 -->
        <router-view />
    </v-app>
</template>
<script>
export default {
    name: 'App',

    mounted(){
        this.$router.push("/auth/login")
        // if(!this.$store.state.alarmman_user.is_logined){
        //     this.$router.push("/auth/login")
        // }
    }
}
</script>